import type {
  AppBodyQuery,
  AppBodyQueryVariables,
} from 'types/graphql'
import type { CellSuccessProps } from '@redwoodjs/web'
import { createContext, useContext } from "react";
import { useParams } from '@redwoodjs/router'

type AppResults = CellSuccessProps<AppBodyQuery, AppBodyQueryVariables>
export type AppContext = {
  me: AppResults['me'],
  membership?: AppResults['me']['memberOf'][number]
  role?: AppResults['me']['memberOf'][number]['role']
  isReadOnlyUser: boolean
  canEdit: boolean
  currentOrg: AppResults['currentOrg']
  partCategories: AppResults['partCategories']
  partNumberSchemas: AppResults['partNumberSchemas']
}
const appContext = createContext<AppContext | undefined>(undefined)
export default appContext
export const useAppContext = () => {
  return useContext(appContext)
}
// export type AppContext

export const useAppContextValue = (args: CellSuccessProps<AppBodyQuery> | undefined): AppContext | undefined => {
  if (!args) return;
  const { orgId } = useParams()
  const { me, currentOrg, partNumberSchemas, partCategories } = args;
  const membership = me.memberOf.find(m => m.orgId === orgId)
  const role = membership?.role;
  return {
    me,
    membership,
    partCategories,
    role,
    currentOrg,
    isReadOnlyUser: role === 'ReadOnly',
    canEdit: role !== 'ReadOnly',
    partNumberSchemas
  }
}
