import Sentry from 'src/lib/sentry'
import { RedwoodProvider } from '@redwoodjs/web'
import possibleTypes from 'src/graphql/possibleTypes'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import { Theme } from '@radix-ui/themes'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'

import './index.css'

export const graphQLClientConfig = {
  cacheConfig: {
    possibleTypes: possibleTypes.possibleTypes,
  },
}

const App = () => (
  <Theme accentColor="yellow">
    <Sentry.ErrorBoundary fallback={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={graphQLClientConfig}
          >
            <Routes />
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </Sentry.ErrorBoundary>
  </Theme>
)

export default App
