import React, { useContext, Fragment } from 'react'
import classNames from 'classnames'
import { Link } from '@redwoodjs/router'
import { Popover, Transition } from '@headlessui/react'
import * as HeadlessForm from '@redwoodjs/forms'
import { PencilSquareIcon, LockClosedIcon } from '@heroicons/react/16/solid'
import AppContext from 'src/lib/appContext'
import { twMerge } from 'tailwind-merge'

export const buttonColors = {
  red: 'bg-red-600 text-gray-100 hover:bg-red-500 border-transparent',
  redDisabled: 'cursor-not-allowed bg-red-200 text-gray-500 border-transparent',
  primary: 'bg-brand-500 text-white hover:bg-brand-600 border-transparent',
  primaryDisabled: 'cursor-not-allowed bg-brand-200 text-gray-500 border-transparent',
  secondary: 'bg-white text-gray-900 hover:bg-gray-50 border-gray-300',
  secondaryDisabled: 'cursor-not-allowed bg-grey-150 text-gray-500 border-gray-200',
} as const

type ButtonClassesInput = {
  className?: string;
  variant?: 'primary' | 'secondary' | 'red';
  disabled?: boolean
  normalize?: boolean
  size?: 'xs' | 'sm' | 'md'
}

export function buttonClasses ({ variant, disabled, className, size, normalize = false }: ButtonClassesInput) {
  variant = variant ?? 'secondary'
  const key = `${variant}${disabled ? 'Disabled' : ''}` as keyof typeof buttonColors
  size = size || 'md'
  return twMerge(classNames(
    normalize && 'flex items-center',
    defaultButtonClasses,
    buttonColors[key],
    {
      'px-2.5 py-1.5 text-sm': size === 'md',
      'px-1.5 py-1 text-xs': size === 'sm',
      'px-1 py-0.5 text-xs': size === 'xs',
    }
  ), className)
}

const defaultButtonClasses = `
rounded-md font-normal shadow-xs focus-visible:outline
focus-visible:outline-2 focus-visible:outline-offset-2 border
focus-visible:outline-brand-500 whitespace-nowrap
`

type ButtonProps = {
  disabled?: boolean
  children: React.ReactNode
  className?: string
  variant?: 'primary' | 'secondary' | 'red';
  type?: 'button' | 'submit' | 'reset'
  size?: 'xs' | 'sm' | 'md'
  writeOnly?: boolean | 'left' | 'right'
};

const ReadOnlyPopover = ({className, onClick, children, writeOnly, ...props}: ButtonProps & Omit<React.HTMLProps<HTMLButtonElement>, "type" | 'size' | 'ref'>) => {
  const cn = classNames(
    className,
    'relative'
  )

  const panelCn = classNames(
    "p-4 my-4 border border-gray-200 rounded-md bg-white absolute z-10 bg-blue-200 w-screen max-w-max text-gray-900",
    writeOnly === 'left' ? 'left-0' : 'right-0'
  )
  return (
    <Popover as={Fragment}>
      <PopoverButton {...props} className={cn} disabled={false} onClickCapture={() => {}}>
        {children}
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Panel className={panelCn}>
            This action requires write access
          </Popover.Panel>
        </Transition>
      </PopoverButton>
    </Popover>
  )
}


const Button: React.FC<
  ButtonProps & Omit<React.HTMLProps<HTMLButtonElement>, "type" | 'size'>
> = ({type = "button", writeOnly, ...props}) => {
  const appContext = useContext(AppContext)
  if (writeOnly && appContext?.isReadOnlyUser) {
    return <ReadOnlyPopover {...props} writeOnly={writeOnly} className={buttonClasses(props)}/>
  }

  return <button
    {...props}
    type={type}
    className={buttonClasses(props)}
  >
   {props.children}
  </button>
}

export default Button

export const FakeButton: React.FC<ButtonProps> = (props) => {
  return <div
    {...props}
    className={buttonClasses({...props, normalize: true})}
  >
   {props.children}
  </div>
}

export const ButtonLink: React.FC<
  ButtonProps & React.ComponentProps<typeof Link>
> = ({writeOnly, ...props}) => {
  const appContext = useContext(AppContext)
  if (writeOnly && appContext?.isReadOnlyUser) {
    return <ReadOnlyPopover variant={props.variant} writeOnly={writeOnly} size={props.size} className={buttonClasses(props)}>
      {props.children}
    </ReadOnlyPopover>
  }

  return <Link
    {...props}
    type="button"
    className={buttonClasses(props)}
  >
   {props.children}
  </Link>
}

// When needed, this component should be imported from ./Form.tsx
// but the source is here since it is most similar to other buttons
export const SubmitButton: React.FC<
  ButtonProps & React.ComponentProps<typeof HeadlessForm.Submit>
> = ({writeOnly, ...props}) => {
  const appContext = useContext(AppContext)
  if (writeOnly && appContext?.isReadOnlyUser) {
    return <ReadOnlyPopover {...props} writeOnly={writeOnly} className={buttonClasses(props)}/>
  }

  return <HeadlessForm.Submit
    {...props}
    className={buttonClasses(props)}
  >
   {props.children}
  </HeadlessForm.Submit>
}

export const PopoverButton: React.FC<
  ButtonProps & React.ComponentProps<typeof HeadlessForm.Submit>
> = ({writeOnly, ...props}) => {
  const appContext = useContext(AppContext)
  if (writeOnly && appContext?.isReadOnlyUser) {
    return <ReadOnlyPopover {...props} writeOnly={writeOnly} className={buttonClasses(props)}/>
  }

  return <Popover.Button
    {...props}
    className={buttonClasses(props)}
  >
   {props.children}
  </Popover.Button>
}

type EditButtonProps = {
  disabled?: boolean
  size: number
  className?: string
  onClick?: () => void
  testId?: string
}
export const EditButton: React.FC<EditButtonProps> = ({ disabled, size, className, onClick, testId }) => {
  const appContext = useContext(AppContext)
  if (appContext?.isReadOnlyUser) {
    return;
  }

  const iconClassName = `w-${size} h-${size} text-gray-500`
  const outerClassName = `${className || ''} has-tooltip flex items-center gap-2 text-sm`
  if (disabled) {
    return <button type="button" disabled className={outerClassName}>
      <LockClosedIcon className={iconClassName + ' mb-0.5'} />
      <div className={`ml-6 tooltip rounded-lg px-3 py-2 bg-gray-800 text-white opacity-80`}>To change, adjust in CAD and import again</div>
    </button>
  }
  return <button type="button" data-testid={`edit-button-${testId}`} className={outerClassName} onClick={onClick}>
    <PencilSquareIcon className={iconClassName} />
  </button>
}
