import { Fragment, ElementType, ReactElement, Children, ReactNode } from 'react'
import classNames from 'classnames'
import { Listbox as HeadlessListBox, ListboxButtonProps, Transition, ListboxProps, ListboxOptionProps} from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { useFormContext, useController } from '@redwoodjs/forms'
import { Combobox, ComboboxOptionProps } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { buttonClasses as regularButtonClasses } from 'src/components/Button'

const baseClasses = `
ring-gray-300
cursor-pointer text-gray-900 focus:outline-none
focus:ring-2 focus:ring-brand-500 text-sm
`

const plainClasses = `
${baseClasses}
relative text-left
`

const buttonClasses = `
${plainClasses} rounded-md bg-whiteshadow-sm bg-white
ring-1 ring-inset ring-gray-300
`

type GetButtonClassesInput = {
  hideRing?: boolean
  variant: 'plain' | 'fullBleed'
  size?: 'sm' | 'md'
}
const getButtonClasses = ({ hideRing, variant, size }: GetButtonClassesInput) => {
  hideRing = variant === 'fullBleed' ? true : hideRing
  size = size || 'md'
  return classNames({
    [plainClasses]: variant === 'plain',
    [baseClasses]: variant === 'fullBleed',
    'py-1 pl-2 pr-8 text-xs': size === 'sm',
    'py-1.5 pl-3 pr-10 text-sm !leading-6': size === 'md',
    'rounded-md bg-whiteshadow-sm bg-white': variant === 'plain',
    'ring-0': hideRing,
    'ring-1 ring-inset': !hideRing,
    'w-full h-full': variant === 'fullBleed'
  })
}

type ButtonProps<T extends ElementType> = Omit<ListboxButtonProps<T>, 'children'> & {
  className?: string
  displayValue: string
  size?: 'sm' | 'md'
  disabled?: boolean
  variant?: 'primary' | 'secondary' | 'red'
}

export const HeadlessButton = HeadlessListBox.Button
export const Button = <T extends ElementType,>({displayValue,size, disabled,variant, ...props}: ButtonProps<T>) => {
  size = size || 'md'
  const className = classNames(
    buttonClasses,
    variant && regularButtonClasses({variant}),
    variant && 'ring-0',
    {
      '!bg-[#efefef4d]': disabled,
      '!text-gray-400': disabled,
      '!cursor-default pointer-events-none': disabled,
      'py-1 pl-2 pr-8 text-xs': size === 'sm',
      'py-1.5 pl-3 pr-10 text-sm !leading-6': size === 'md',
    },
  )
  const classNameWithOverrides = twMerge(className, props.className)
  return <HeadlessListBox.Button {...props} className={classNameWithOverrides} tabIndex={disabled ? -1 : undefined}>
    <span className="block truncate">{displayValue}</span>
    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </span>
  </HeadlessListBox.Button>
}

export const PlainButton = <T extends ElementType,>(props: ButtonProps<T> & { size?: 'sm' | 'md' }) => {
  const size = props.size || 'md'
  const className = classNames(
    props.className,
    plainClasses,
    {
      'py-1 pl-2 pr-8 text-xs': size === 'sm',
      'py-1.5 pl-3 pr-10 text-sm leading-6': size === 'md',
    }
  )
  return <HeadlessListBox.Button className={className}>
    <span className="block truncate">{props.displayValue}</span>
    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </span>
  </HeadlessListBox.Button>
}

type ButtonChildFunction = Extract<ListboxButtonProps<any>['children'], (a: any) => any>
type ButtonRenderProps = Parameters<ButtonChildFunction>[0]
type ButtonRenderFunction = (r: ButtonRenderProps) => ReactNode
type UncontrolledButtonProps<T extends ElementType> = {
  children?: ButtonRenderFunction
  displayFunction?: ButtonRenderFunction
  className?: string
  hideRing?: boolean,
  variant?: 'plain' | 'fullBleed'
  disabled?: boolean
  autoFocus?: boolean
  size?: 'sm' | 'md'
  wrapperClassName?: string
} & Omit<ListboxButtonProps<T>, 'children'>

export const UncontrolledButton = <T extends ElementType,>(props: UncontrolledButtonProps<T>) => {
  const { autoFocus, children, disabled, displayFunction, hideRing, className: passed, variant = 'plain', wrapperClassName, size, ...rest } = props;

  const className = twMerge(
    getButtonClasses({ hideRing, variant, size }),
    passed,
    disabled ? 'bg-gray-50 pointer-events-none text-gray-400' : ''
  )

  const injection: ButtonRenderFunction = (r) =>
    (children ?? displayFunction ?? (b => b.value))(r);

  return <HeadlessListBox.Button  { ...rest } autoFocus={autoFocus} className={className}>
    {r =>
      <>
        <span className={wrapperClassName ?? "block truncate"}> {injection(r)} </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
          {disabled ? null : <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
        </span>
      </>
    }
  </HeadlessListBox.Button>
}

export const optionsClasses = `
min-w-56
absolute z-20 mt-1 max-h-96
overflow-auto rounded-md bg-white py-1
shadow-lg ring-1 ring-black ring-opacity-5
focus:outline-none
`

type OptionsProps = {
  open?: boolean
  children: React.ReactNode
  className?: string
  size?: 'sm' | 'md'
  align?: 'left' | 'right'
} & React.HTMLProps<HTMLButtonElement>;
export const HeadlessOptions = HeadlessListBox.Options;
export const Options: React.FC<OptionsProps> = (props) => {
  const className = classNames(
    props.className,
    optionsClasses,
    props.align !== 'left' ? 'right-0' : ''
  )
  return <Transition
    show={props.open}
    as={Fragment}
    leave="transition ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0">
    <HeadlessListBox.Options className={className}>
      {props.children}
    </HeadlessListBox.Options>
  </Transition>
}

export const ComboboxOptions: React.FC<OptionsProps> = (props) => {
  const className = classNames(
    props.className,
    optionsClasses,
    props.align !== 'left' ? 'right-0' : ''
  )
  return <Transition
    show={props.open}
    as={Fragment}
    leave="transition ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0">
    <Combobox.Options className={className}>
      {props.children}
    </Combobox.Options>
  </Transition>
}

const optionClasses = `
cursor-pointer relative select-none py-3 pl-5 pr-9
`
type OptionProps = {
  //WARNING: non-string values only should work with controlled selects
  value: any,
  display: string | ReactNode
  className?: string
} & Omit<React.HTMLProps<HTMLButtonElement>, 'value'>;
export const HeadlessOption = HeadlessListBox.Option;
export const Option: React.FC<OptionProps> = (props) => {
  const className = classNames(
    props.className,
    optionClasses,
  )
  return <HeadlessListBox.Option
    key={props.value}
    className={({ active }) =>
      classNames(
        active ? 'bg-brand-500 text-white' : '',
        props.disabled ? 'bg-gray-50 text-gray-400 pointer-events-none' : '',
        className
      )
    }
    value={props.value}
  >
    {({ selected, active }) => (
      <>
        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
          {props.display}
        </span>

        {selected ? (
          <span
            className={classNames(
              // active ? 'text-white' : 'text-brand-500',
              'absolute inset-y-0 right-0 flex items-center pr-4'
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </>
    )}
  </HeadlessListBox.Option>
}

export const ComboboxOption: React.FC<any> = (props) => {
  const className = classNames(
    props.className,
    optionClasses,
  )
  return <Combobox.Option
    className={({ active }) =>
      className + (active ? 'bg-brand-500 text-white' : '')
    }
    key={props.value}
    value={props.value}
  >
    {props.children}
  </Combobox.Option>
}

export const ComboboxButton: React.FC<any> = (props) => {
  if (props.disabled) {
    return <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none bg-gray-100" data-testid='combobox-button' />
  }
  return <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" data-testid='combobox-button'>
    <ChevronDownIcon
      className="h-5 w-5 text-gray-400"
      aria-hidden="true"
    />
  </Combobox.Button>
}

export const ListBox = HeadlessListBox

type ControllerParams = Parameters<typeof useController>[0]
type HookedListBoxProps = ControllerParams & ListboxProps<any, any, any> & {
  variant?: 'plain' | 'fullBleed'
}

export const HookedListBox = ({as = 'div', className: passed, variant, ...props}: HookedListBoxProps) => {
  variant = variant || 'plain'
  const {name, defaultValue, rules, shouldUnregister, control, disabled, ...rest } = props;
  const methods = useFormContext();
  const { field } = useController({
    control: control ?? methods.control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    disabled
  })

  const className = variant === 'fullBleed' ? classNames('absolute top-0 right-0 left-0 bottom-0', passed) : classNames('relative', passed)

  return <ListBox {...rest} as={as} className={className} {...field}/>
}
